/**
 * User Management
 * User Management
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 1 = ResetPassword
 */
export enum UserTokenType {
    Unknown = 0,
    ResetPassword = 1
}

